.content {
  padding-top: 4%;
  text-align: left;
}

.info-body {
  font-size: 18px;
}

.para-end {
  margin-bottom: 3%;
}

.content-heading {
  margin-bottom: 3%;
}
