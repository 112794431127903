.icons-root {
  float: right;
  margin-top: 0.5%;
  margin-right: 0.5%;
}

.face {
  max-width: 100%;
  max-height: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}
