.card {
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  color: black;
  width: inherit;
  margin-bottom: 20px;
}

.webcard-link {
  text-decoration: none !important;
}

.card:hover {
  opacity: 1;
  transform: translateY(2px);
}
