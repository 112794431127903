.input-content {
  resize: none;
  margin-bottom: 1em;
  border-radius: 2px;
  padding: 4px;
  border: none;
  outline: none;
}

.input-name {
  padding: 4px;
  border: none;
  outline: none;
  margin-bottom: 20px;
}

.posted-message {
  width: inherit;
  margin-top: 10px;
}

.submit-btn {
  background-color: #3b4252 !important;
  border-radius: 4px;
  border: none;
  color: #d8dee9;
  text-decoration: none;
  padding: 8px 20px;
  font-size: 20px;
  border-style: none;
  margin-bottom: 20px;
}

.submit-btn:focus {
  outline: 0;
}
