.layout-root {
  background-color: #3468c0 !important;
}

.header-desktop {
  border-radius: 0 !important;
  height: 100%;
  display: flex;
  padding: 2%;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.header-mobile {
  border-radius: 0 !important;
  height: inherit;
  display: flex;
  padding: 2%;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.header-text {
  color: #ffdd95;
  padding-left: 10%;
  margin: auto 0;
}
